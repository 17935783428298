
import styles from './Card.module.scss'
import React, {DetailedHTMLProps, HTMLAttributes} from "react";
import {Person} from "@/types/person";
import {InteractionStats} from "@/types/interactionStats";
import Mensenlinq from "@/Atoms/Icons/Mensenlinq";
import Pen from "@/Atoms/Icons/Pen";
import Flower from "@/Atoms/Icons/Flower";
import Candle from "@/Atoms/Icons/Candle";
import Birth from "@/Atoms/Icons/Birth";
import Deceased from "@/Atoms/Icons/Deceased";
import Card from "@/Atoms/Card";
import moment from "moment/moment";

interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  person: Person,
  stats: InteractionStats,
  baseUrl?: (person: Person) => string,
}

export default function ObituaryCard({className, person, stats, baseUrl, ...props}: CardProps) {

  const dateFormat = 'DD-MM-YYYY';
  const birthDate = person.birth_date ? moment(person.birth_date).format(dateFormat) : null
  const deceasedDate = person.deceased_date ? moment(person.deceased_date).format(dateFormat) : null

  let params = new URLSearchParams(location.search);
  const wlCHash = params.get('wlCHash')
  const wlCId = params.get('wlCId')
  const wlCHostname = params.get('wlCHostname')



  let obituaryUrl = baseUrl ? baseUrl(person) : `/overlijdensberichten/${person.slug}`
  if (wlCHostname){
    obituaryUrl = `${obituaryUrl}?wlCHostname=${wlCHostname}`
  }

  const {name, birth_date, birth_place, deceased_date, deceased_place, papers, avatar} = person
  return <Card className={`${styles.card} ${className || '' }`} {...props} href={obituaryUrl}>
    <div className={styles.avatar}>
      {avatar ? <img src={avatar} alt={''}/> : <Mensenlinq />}
    </div>
    <div className={styles.content}>
      <div className={styles.title}>{name}</div>
      {(birth_place || birthDate) && (
        <div className={styles.meta}><Birth />{birth_place ? `${birth_place}, ` : ''} {birthDate}</div>
      )}
      <div className={styles.meta}><Deceased />{deceased_place ? `${deceased_place}, ` : ''} {deceasedDate}</div>
      <div className={styles.meta}>{papers.map(paper => paper.title).join(', ')}</div>
    </div>
    <ul className={styles.stats}>
      <li><Pen />{stats.condolences}</li>
      <li><Flower /> {stats.flowers}</li>
      <li><Candle /> {stats.candles}</li>
    </ul>

  </Card>
}
